
import { Component, Vue } from "vue-property-decorator";
import LoginForm from "@/components/forms/LoginForm.vue";
import NotAuthHeader from "@/components/NotAuthHeader.vue";
import ManagementFooter from "@/components/ManagementFooter.vue";
import PasswordForm from "@/components/forms/components/PasswordForm.vue";
import AuthModule from "@/store/auth";
import Cognito from "@/plugins/cognito";

@Component({
  components: {
    LoginForm,
    NotAuthHeader,
    ManagementFooter,
    PasswordForm,
  },
})
export default class ChangePassword extends Vue {
  private password = "";
  private confirmPassword = "";
  private currentPassword = "";
  private email = "";
  private loading = false;
  private showPass = false;
  private showLabel = false;
  private showPassConfirm = false;
  private showPassCurrent = false;

  private get beforeChangePassRoot() {
    return AuthModule.beforeChangePassRoot;
  }

  private async mounted() {
    try {
      // if (this.id) {
      //   const res = await MemberService.getResetPasswordInfo(this.id);
      //   this.email = res.data.data[0].email;
      // }
    } catch (error: any) {
      throw new Error(error);
    }
  }

  private submit() {
    try {
      this.loading = true;
      Cognito.changePassword(this.currentPassword, this.password, this.callback);
    } catch (error) {
      this.loading = false;
      this.showError();
    }
  }

  private showError() {
    this.showLabel = true;
    setTimeout(() => {
      this.showLabel = false;
    }, 3000);
  }

  private callback(err: any, result: any) {
    if (err) {
      this.loading = false;
      this.showError();
      throw new Error(err);
    } else {
      this.$router.push({ name: "ChangePasswordComplete" });
      this.loading = false;
    }
  }

  private passwordToggle() {
    this.showPass = !this.showPass;
  }
  private confirmPasswordToggle() {
    this.showPassConfirm = !this.showPassConfirm;
  }
  private currentPasswordToggle() {
    this.showPassCurrent = !this.showPassCurrent;
  }
  private back() {
    const routeName = this.beforeChangePassRoot ?? "Login";
    AuthModule.changePassRoot("");
    this.$router.push({ name: routeName });
  }
}
