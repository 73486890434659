var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('NotAuthHeader'),_c('div',{staticClass:"password-form"},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit }){return [_c('form',{staticClass:"edit-form d-flex flex-column justify-center",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('v-card',{staticClass:"mx-auto mt-10 pa-10 card-wrap",attrs:{"outlined":"","width":"660px"}},[_c('h2',{staticClass:"text-center mb-5 text-sm-h5 font-weight-bold"},[_vm._v("パスワード変更")]),_c('div',{staticClass:"form-row"},[_c('p',{staticClass:"mr-5 mb-0"},[_vm._v("現在のパスワード")]),_c('ValidationProvider',{staticClass:"form-field",attrs:{"name":"現在のパスワード","rules":{
                required: true,
                regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/,
                passwordLength: true,
              }},scopedSlots:_vm._u([{key:"default",fn:function({ errors, valid }){return [_c('v-text-field',{staticClass:"mt-3",attrs:{"dense":"","outlined":"","id":"currentPassword","error-messages":errors,"success":valid,"append-icon":_vm.showPassCurrent ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.showPassCurrent ? 'text' : 'password'},on:{"click:append":_vm.currentPasswordToggle},model:{value:(_vm.currentPassword),callback:function ($$v) {_vm.currentPassword=$$v},expression:"currentPassword"}})]}}],null,true)})],1),_c('div',{staticClass:"form-row password mt-5"},[_c('p',{staticClass:"mr-5 mb-0"},[_vm._v("新しいパスワード")]),_c('PasswordForm',{attrs:{"password":_vm.password}},[_c('ValidationProvider',{staticClass:"form-field",attrs:{"name":"新しいパスワード","rules":{ required: true, regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/, passwordLength: true },"vid":"password"},scopedSlots:_vm._u([{key:"default",fn:function({ errors, valid }){return [_c('v-text-field',{attrs:{"dense":"","outlined":"","id":"password","error-messages":errors,"success":valid,"persistent-hint":"","append-icon":_vm.showPass ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.showPass ? 'text' : 'password'},on:{"click:append":_vm.passwordToggle},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}],null,true)})],1)],1),_c('div',{staticClass:"form-row"},[_c('p',{staticClass:"mr-5 mb-0"},[_vm._v("新しいパスワード(確認)")]),_c('ValidationProvider',{staticClass:"form-field",attrs:{"name":"新しいパスワード(確認)","rules":{
                required: true,
                regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/,
                passwordLength: true,
                confirmed: 'password',
              }},scopedSlots:_vm._u([{key:"default",fn:function({ errors, valid }){return [_c('v-text-field',{staticClass:"mt-3",attrs:{"dense":"","outlined":"","id":"confirmPassword","error-messages":errors,"success":valid,"append-icon":_vm.showPassConfirm ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.showPassConfirm ? 'text' : 'password'},on:{"click:append":_vm.confirmPasswordToggle},model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=$$v},expression:"confirmPassword"}})]}}],null,true)})],1),_c('v-alert',{attrs:{"type":"error","value":_vm.showLabel}},[_vm._v("現在のパスワードが間違っています")])],1),_c('div',{staticClass:"buttons"},[_c('v-btn',{staticClass:"next-btn btn ok",attrs:{"id":"submit","type":"submit","height":"50","loading":_vm.loading}},[_vm._v("次へ")]),_c('v-btn',{staticClass:"cancel btn",attrs:{"height":"50"},on:{"click":_vm.back}},[_vm._v("キャンセル")])],1)],1)]}}])})],1),_c('ManagementFooter')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }